import React from "react";

import Heading from "components/heading";
import HeroSection from "components/hero-section";
import Link from "next/link";

import Page from "../components/layout/page";

const NotFoundPage = () => (
  <Page title="Page Not Found" description="Enerflo Custom 404 Page Not Found.">
    <div className="bg-gradient-to-t from-gray-100 to-white">
      <HeroSection>
        <HeroSection.Content className="md:text-center my-16 md:my-24">
          <HeroSection.Header>
            <Heading tag="h2">D’oh! Looks like you took a wrong turn.</Heading>
          </HeroSection.Header>
          <div className="text-base text-gray-500">
            <p>There’s nothing to see here, but let’s try to get you back on track.</p>
            <p>
              Check out{" "}
              <Link href="/features">
                <a className="text-primary">Enerflo’s platform features</a>
              </Link>{" "}
              or{" "}
              <Link href="/about/contact">
                <a className="text-primary">contact us</a>
              </Link>
              .
            </p>
          </div>
        </HeroSection.Content>
      </HeroSection>
    </div>
  </Page>
);

export default NotFoundPage;
